<template>
  <div>
    <div class="counter">
      <div @click="decreaseCounter(0)" class="counter-item minus"><SelectIcon /></div>
      <input type="number" v-model="setValue[id]" />
      <div @click="increaseCounter(10)" class="counter-item plus"><SelectIcon /></div>
    </div>
    <div v-if="errorMessages.length">
      <p v-for="error in errorMessages" :key="error.id" class="counter__error">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
import SelectIcon from "../../assets/images/icons/select-icon.svg";
export default {
  name: "Counter",
  props: {
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    id: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    SelectIcon,
  },
  methods: {
    increaseCounter(increaseLimit) {
      if (this.setValue[this.id] < increaseLimit) this.setValue[this.id]++;
    },
    decreaseCounter(decreaseLimit) {
      if (this.setValue[this.id] > decreaseLimit) this.setValue[this.id]--;
    },
  },
};
</script>

<style lang="scss" scoped>
.counter {
  @include flex-row;

  input {
    width: 64px;
    background: $g-color-white;
    border-radius: 5px;
    height: 36px;
    border: none;
    outline: none;
    font-size: 14px;
    text-align: center;
    border: 1px solid #131313;
    &::placeholder {
      color: $mainSecondTextColor;
      padding: 16px 0px;
      font-size: 14px;
    }
  }
  &__error {
    color: $elementsColor;
    padding-left: 10px;
    padding-top: 10px;
    text-align: left;
    font-size: $g-font-small-size;
  }

  .counter-item {
    display: flex;
    padding: 0 5px;
    cursor: pointer;

    svg {
      margin: auto;
    }
  }

  .counter-item.plus {
    transform: rotate(-90deg);
  }

  .counter-item.minus {
    transform: rotate(90deg);
  }
  /deep/input::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  /deep/input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  /deep/input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
