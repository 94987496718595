<template>
  <div class="input-main-select__wrap">
    <div class="input-main-select__inputs">
      <div :class="['input-main-select']">
        <label :for="label">
          <v-select
            :id="label"
            :placeholder="placeholder"
            :options="optionList"
            @search="onSearch"
            @input="onChange"
            :value="value"
            label="displayName"
            :disabled="disabled"
            :pushTags="true"
            :filterable="false"
          >
            <template slot="no-options"> Введите адресс </template>
            <template v-if="optionList.length" slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.address.road }} {{ option.address.houseNumber }} {{ option.address.city
                }}{{ option.address.village }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.address.city }}{{ option.address.town }}{{ option.address.village
                }}{{
                  (option.address.city != null &&
                    option.address.city != undefined &&
                    option.address.city != "") ||
                  (option.address.town != null &&
                    option.address.town != undefined &&
                    option.address.town != "") ||
                  (option.address.village != null &&
                    option.address.village != undefined &&
                    option.address.village != "")
                    ? ", "
                    : ""
                }}
                {{ option.address.road }}
                {{
                  option.address.houseNumber != null &&
                  option.address.houseNumber != undefined &&
                  option.address.houseNumber != ""
                    ? ", "
                    : ""
                }}
                {{ option.address.houseNumber }}
              </div>
            </template>
          </v-select>
        </label>
      </div>
      <div v-if="triggerHouseNumber" class="input-main-select__house-number">
        <InputText
          :id="idHouse"
          :label="idHouse"
          :type="'text'"
          :onChange="onChangeHouse"
          :placeholder="'Номер дома'"
          isBordered
        ></InputText>
      </div>
    </div>
    <div v-if="errorMessages.length">
      <p v-for="error in errorMessages" :key="error.id" class="input-main-select__error">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import InputText from "../inputs/InputText.vue";
import commonApi from "../../../api/common";
export default {
  name: "InputSelectAdress",
  data() {
    return {
      optionList: [],
      triggerHouseNumber: false,
    };
  },
  components: {
    InputText,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    idHouse: {
      type: String,
      default: "",
    },
    labelName: {
      type: String,
      default: "name",
    },
    label: {
      type: String,
      default: "",
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _.debounce((loading, search, vm) => {
      const url = `?q=${search}, Украина`;
      if (search != "") {
        loading(true);
        commonApi
          .geoSearch(url)
          .then((res) => {
            if (res.length > 0) {
              vm.optionList = res;

              return;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            loading(false);
          });
      }
    }, 350),
    onChange(value) {
      this.setValue[this.id] = value;
      if (value.address.houseNumber === undefined) {
        this.triggerHouseNumber = true;
      } else this.triggerHouseNumber = false;
    },
    onChangeHouse(value) {
      this.setValue[this.idHouse] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-main-select {
  color: $mainColor;
  width: 360px;
  padding: 3px;
  border: 1px solid $mainColor;
  border-radius: 5px;
  height: 48px;

  &__inputs {
    position: relative;
    display: flex;
  }

  &__house-number {
    position: static;
    width: 130px;
    margin-left: 20px;
    top: 0;
    left: 100%;
  }

  &__error {
    color: $elementsColor;
    position: absolute;
    padding-top: 10px;
    font-size: $g-font-small-size;
  }
}

/deep/ .v-select {
  height: 100%;
}

/deep/ .vs__dropdown-toggle {
  border: none;
  height: 100%;
}
/deep/ .vs__dropdown-option--highlight {
  background-color: $mainColor;
}
/deep/ .vs__dropdown-menu {
  padding: 0;
  margin-top: 8px;
}
/deep/ .vs__clear {
  display: none;
}
/deep/ .vs__actions {
  display: none;
}
/deep/ .vs__search {
  &::placeholder {
    color: $mainSecondTextColor;
  }
}
</style>
