<template>
  <Main>
    <div class="order-create">
      <div class="order-create__container">
        <h1 v-if="triggerUpdate">Редактировать заказ</h1>
        <h1 v-else>Создать заказ</h1>
        <form @submit.prevent="sendNewOrder">
          <div class="order-create__form-container">
            <div class="order-create__label">Заказчик</div>
            <InputModal
              @click.native="handleOpenClientModal()"
              :set-value="formDataToSend"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :placeholder="'Выбрать'"
              :error-messages="error['clientId']"
              isBordered
            />
            <div class="order-create__label">Наименование груза</div>
            <InputText
              :set-value="formDataToSend"
              :id="'cargoName'"
              :label="'cargoName'"
              :type="'text'"
              :placeholder="'Ввести'"
              :error-messages="error['cargoName']"
              isBordered
            ></InputText>
            <div class="order-create__label">Телефон диспетчера</div>
            <InputText
              :set-value="formDataToSend"
              :id="'dispatcherPhone'"
              :label="'dispatcherPhone'"
              :type="'text'"
              :placeholder="'+38 (000) 000 00 00'"
              :error-messages="error['dispatcherPhone']"
              isBordered
              thisPhone
            ></InputText>

            <div class="order-create__label">Откуда</div>
            <div class="order-create__address-group">
              <InputSelectAdress
                :id="'addressFrom'"
                :placeholder="'Ввести адрес'"
                :set-value="formDataToSend"
                :value="formDataToSend.addressFrom"
                :error-messages="error['addressFrom']"
                :idHouse="'numberHouseFrom'"
              />
              <MapChoose :set-value="formDataToSend" :name="'addressFrom'" />
            </div>
            <div class="order-create__label">Куда</div>
            <div class="order-create__chekbox-container">
              <InputCheckboxMain :label="'По городу'" :id="'isCity'" :set-value="formDataToSend" />
            </div>
            <div class="order-create__address-group">
              <InputSelectAdress
                :id="'addressTo'"
                :placeholder="'Ввести адрес'"
                :set-value="formDataToSend"
                :value="formDataToSend.addressTo"
                :error-messages="error['addressTo']"
                :idHouse="'numberHouseTo'"
              />
              <MapChoose :set-value="formDataToSend" :name="'addressTo'" />
            </div>
            <div v-if="triggerUpdate" class="order-create__form-container statuses">
              <div class="order-create__label big">Статус</div>
              <InputMainSelect
                :id="'status'"
                :placeholder="'Выбрать'"
                :option-list="statuses"
                :set-value="formDataToSend"
                :value="formDataToSend.status"
                :label-name="'title'"
                :error-messages="error['status']"
              />
            </div>
            <div class="order-create__form-container statuses">
              <div class="order-create__label">Способ оплаты</div>
              <InputMainSelect
                :id="'paymentType'"
                :placeholder="'Выбрать'"
                :option-list="paymentTypes"
                :set-value="formDataToSend"
                :value="formDataToSend.paymentType"
                :label-name="'name'"
                :error-messages="error['paymentType']"
              />
            </div>
            <div class="order-create__button-date">
              <div class="order-create__button-date_item">
                <div class="order-create__label">Дата</div>

                <InputDate
                  :value="formDataToSend.date"
                  :id="'date'"
                  :set-value="formDataToSend"
                  :error-messages="error['dateTime']"
                />
              </div>
              <div class="order-create__button-date_item">
                <div class="order-create__label">Время</div>
                <InputTime
                  :id="'time'"
                  :placeholder="'Выбрать'"
                  :set-value="formDataToSend"
                  :value="formDataToSend.time"
                  :date="formDataToSend.date"
                ></InputTime>
              </div>
            </div>
          </div>
          <div v-if="formDataToSend.isCity" class="order-create__time">
            <div class="order-create__time_item">
              <div class="order-create__label">Количество часов</div>
              <Counter
                :setValue="formDataToSend"
                :id="'countOfHours'"
                :error-messages="error['countOfHours']"
              />
            </div>
            <div class="order-create__time_item">
              <div class="order-create__label">Количество минут</div>
              <InputRadio :set-value="formDataToSend" :id="'minutes'" />
            </div>
          </div>
          <div class="order-create__form-container">
            <div class="order-create__label">Тип авто</div>
            <div class="order-create__type-car">
              <InputSelectTypeCars
                :id="'typesOfCars'"
                :placeholder="'Выбрать'"
                :option-list="typesOfCars"
                :set-value="formDataToSend"
                :value="formDataToSend.typesOfCars"
                :error-messages="error['typeOfCarId']"
              />
            </div>
          </div>
          <div
            v-if="formDataToSend.typesOfCars != null && formDataToSend.typesOfCars.id == 5"
            class="order-create__nonstandart"
          >
            <div class="order-create__nonstandart_item">
              <div class="order-create__label">Вес</div>
              <InputText
                :set-value="formDataToSend"
                :id="'weight'"
                :label="'weight'"
                :type="'number'"
                isBordered
              ></InputText>
            </div>
            <div class="order-create__nonstandart_item">
              <div class="order-create__label">Длина</div>
              <InputText
                :set-value="formDataToSend"
                :id="'length'"
                :label="'length'"
                :type="'number'"
                isBordered
              ></InputText>
            </div>
            <div class="order-create__nonstandart_item">
              <div class="order-create__label">Ширина</div>
              <InputText
                :set-value="formDataToSend"
                :id="'width'"
                :label="'width'"
                :type="'number'"
                isBordered
              ></InputText>
            </div>
            <div class="order-create__nonstandart_item">
              <div class="order-create__label">Висота</div>
              <InputText
                :set-value="formDataToSend"
                :id="'height'"
                :label="'height'"
                :type="'number'"
                isBordered
              ></InputText>
            </div>
            <div class="order-create__nonstandart_item">
              <div class="order-create__label">Объем</div>
              <InputText
                :set-value="formDataToSend"
                :id="'volume'"
                :label="'volume'"
                :type="'number'"
                isBordered
              ></InputText>
            </div>
          </div>
          <div class="order-create__form-container">
            <div class="order-create__label">
              {{ formDataToSend.isCity ? "Тариф, грн/час" : "Тариф, грн/км" }}
            </div>
            <InputText
              :set-value="formDataToSend"
              :id="'rate'"
              :label="'rate'"
              :type="'text'"
              :placeholder="'Ввести сумму'"
              :error-messages="error['rate']"
              isBordered
            ></InputText>
            <div v-if="!formDataToSend.isCity" class="order-create__label">Дистанция</div>
            <InputText
              v-if="!formDataToSend.isCity"
              :set-value="formDataToSend"
              :id="'distance'"
              :label="'distance'"
              :type="'number'"
              :placeholder="'Ввести дистанцию (км)'"
              :error-messages="error['distance']"
              isBordered
            ></InputText>
            <div class="order-create__label">Сумма, грн</div>
            <InputText
              :set-value="formDataToSend"
              :id="'summ'"
              :label="'summ'"
              :type="'number'"
              :placeholder="'Ввести сумму'"
              :error-messages="error['summ']"
              isBordered
            ></InputText>
            <div class="order-create__chekbox-container">
              <InputCheckboxMain
                :label="'Страховка'"
                :id="'insurance'"
                :set-value="formDataToSend"
              />
            </div>
            <div v-if="formDataToSend.insurance" class="order-create__insurance_container">
              <div class="order-create__label no-padding">Оценка груза, грн</div>
              <InputText
                :set-value="formDataToSend"
                :id="'costEstimate'"
                :label="'costEstimate'"
                :type="'number'"
                :placeholder="'Ввести сумму'"
                :error-messages="error['costEstimate']"
                isBordered
              ></InputText>
            </div>
            <div class="order-create__label">Грузчики</div>
            <div class="order-create__movers">
              <div class="order-create__movers_disc">1 - {{ moversRate }} грн 1 час</div>
              <div class="order-create__movers__counter">
                <Counter :setValue="formDataToSend" :id="'numberOfMovers'" />
              </div>
            </div>
            <div class="order-create__label">Ссылка на карту</div>
            <InputText
              :set-value="formDataToSend"
              :id="'linkUrl'"
              :label="'linkUrl'"
              :type="'text'"
              :placeholder="'ссылка'"
              :error-messages="error['links0Url']"
              isBordered
            ></InputText>
            <div class="order-create__label">Тип загрузки/выгрузки</div>
            <div class="order-create__type-loading">
              <div
                class="order-create__checkbox"
                v-for="(loadingType, index) in loadingTypes"
                :key="index"
              >
                <InputCheckboxMain
                  :label="loadingType.name"
                  :id="loadingType.id + ''"
                  :set-value="formDataToSend.loadingTypes"
                />
              </div>
            </div>
            <div class="order-create__label">Описание</div>
            <InputTextarea
              :set-value="formDataToSend"
              :id="'description'"
              :label="'description'"
              :placeholder="'Ввести текст'"
              :error-messages="error['description']"
              isBordered
            />
            <div class="order-create__label no-padding padding-bottom">Особенности груза</div>
            <div class="order-create__checkbox" v-for="feature in cargoFeatures" :key="feature.id">
              <InputCheckboxMain
                :label="feature.name"
                :id="feature.id + ''"
                :set-value="formDataToSend.cargoFeatures"
              />
            </div>
            <div class="order-create__label">Дополнительные характеристики (не обязательно)</div>
            <div class="order-create__checkbox">
              <InputRadioMain
                :values="bodyTypes"
                :set-value="formDataToSend"
                :id="'bodyTypeName'"
              />
            </div>
          </div>
          <div class="order-create__price">Цена: {{ price }} грн</div>
          <div class="order-create__submit">
            <ButtonMain v-if="triggerUpdate" @click.native="handleOpenConfirmModal">
              Редактировать
            </ButtonMain>
            <ButtonMain v-else :type="'submit'">Создать</ButtonMain>
          </div>
        </form>
      </div>
    </div>
    <DefaultModal :modalCenter="true" v-if="clientsModal" :on-close="handleCloseClientModal">
      <div class="client-table__header">
        <h1>Выбор заказчика</h1>
        <div class="client-table__search">
          <InputSearch
            :label="'search'"
            :search="searchClients"
            :placeholder="'Поиск по номеру телефона заказчика'"
          />
        </div>
      </div>
      <ScrollList
        ><CustomTable
          :data="clientsList"
          :deleted-keys="tableOptions.deletedKeys"
          :rename-columns="tableOptions.renameColumn"
          :custom-order="tableOptions.customOrder"
          :is-action-block-visible="tableOptions.isActionBlockVisible"
          :isChooseList="tableOptions.isChooseList"
          :handleChoose="handleChooseClient"
          :handleFilter="handleFilter"
          :direction="directionSort"
          :order="orderSort"
      /></ScrollList>
    </DefaultModal>
    <DefaultModal v-if="updateModal" :on-close="handleCloseConfirmModal">
      <ConfirmModalContent :on-cancel="handleCloseConfirmModal" :on-success="sendNewOrder">
        <template v-slot:description>
          <p v-if="order.status === 'expired'">Заказ номер {{ order.id }} будет возвращен в эфир</p>
          <p v-else>Вы уверены, что хотите редактировать заказ номер {{ order.id }}?</p>
        </template>
        <template v-slot:cancel> Отмена </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <DefaultModal v-if="timeModal" :on-close="handleCloseTimeModal">
      <ConfirmModalContent :on-cancel="handleCloseTimeModal" :on-success="handleCloseTimeModal">
        <template v-slot:description>
          <p>Укажите корректное время!</p>
        </template>
        <template v-slot:cancel> ОК </template>
        <template v-slot:success> Отмена </template>
      </ConfirmModalContent>
    </DefaultModal>
    <DefaultModal
      v-if="Object.keys(error).length != 0 && error.message"
      :on-close="handleCloseErrorModal"
    >
      <ErrorModalContent :errorMessages="[error.message]" />
    </DefaultModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import { orderSendData, ordersListReverse } from "../helpers/dataIteration";
import ordersApi from "../api/order";
import moment from "moment";
import commonApi from "../api/common";

import Main from "../templets/Main.vue";
import FetchSpinnerModal from "../components/molecules/FetchSpinnerModal.vue";
import InputModal from "../components/atoms/inputs/InputModal.vue";
import DefaultModal from "../components/molecules/DefaultModal.vue";
import ScrollList from "../components/atoms/ScrollList.vue";
import CustomTable from "../components/molecules/CustomTable.vue";
import InputSelectAdress from "../components/atoms/inputs/InputSelectAdress.vue";
import InputDate from "../components/atoms/inputs/InputDate.vue";
import InputTime from "../components/atoms/inputs/InputTime.vue";
import InputText from "../components/atoms/inputs/InputText.vue";
import ButtonMain from "../components/atoms/buttons/ButtonMain.vue";
import InputCheckboxMain from "../components/atoms/inputs/InputCheckboxMain.vue";
import InputTextarea from "../components/atoms/inputs/InputTextarea.vue";
import Counter from "../components/molecules/Counter.vue";
import InputSelectTypeCars from "../components/atoms/inputs/InputSelectTypeCars.vue";
import ConfirmModalContent from "../components/molecules/ConfirmModalContent.vue";
import ErrorModalContent from "../components/molecules/ErrorModalContent.vue";
import InputSearch from "../components/atoms/inputs/InputSearch.vue";
import InputRadio from "../components/atoms/inputs/InputRadio.vue";
import MapChoose from "../components/molecules/MapChoose.vue";
import InputRadioMain from "../components/atoms/inputs/InputRadioMain.vue";
import InputMainSelect from "../components/atoms/inputs/InputMainSelect.vue";

export default {
  name: "OrderCreate",
  components: {
    Main,
    FetchSpinnerModal,
    InputModal,
    DefaultModal,
    ScrollList,
    CustomTable,
    InputSelectAdress,
    InputDate,
    InputTime,
    InputText,
    ButtonMain,
    InputCheckboxMain,
    InputTextarea,
    Counter,
    InputSelectTypeCars,
    ConfirmModalContent,
    ErrorModalContent,
    InputSearch,
    InputRadio,
    MapChoose,
    InputRadioMain,
    InputMainSelect,
  },
  data() {
    return {
      updateModal: false,
      clientsModal: false,
      timeModal: false,
      search: null,
      formDataToSend: {
        id: null,
        isCity: false,
        name: "",
        addressFrom: null,
        addressTo: null,
        numberHouseFrom: null,
        numberHouseTo: null,
        loadingTypes: {},
        cargoName: "",
        linkUrl: "",
        dispatcherPhone: "",
        distance: null,
        date: "",
        time: null,
        minutes: "00",
        countOfHours: 2,
        rate: null,
        insurance: false,
        costEstimate: "",
        cargoFeatures: {},
        description: "",
        numberOfMovers: 0,
        typesOfCars: null,
        bodyTypes: null,
        weight: null,
        length: null,
        width: null,
        height: null,
        volume: null,
        summ: 0,
        bodyTypeName: null,
        status: null,
        paymentType: null,
      },
      directionSort: null,
      orderSort: "",
      triggerSumm: false,
      tableOptions: {
        isChooseList: true,
        deletedKeys: ["id"],
        isActionBlockVisible: true,
        renameColumn: [
          {
            from: "name",
            to: "ФИО",
          },
          {
            from: "phone",
            to: "Телефон",
          },
          {
            from: "created_at",
            to: "Дата регистрации",
          },
        ],
        customOrder: ["id", "name", "phone", "created_at"],
      },
      bodyTypes: [],
    };
  },
  watch: {
    "formDataToSend.rate": {
      handler: function (val, oldVal) {
        if (this.triggerUpdate) {
          if (this.triggerSumm) {
            this.formDataToSend.summ = this.price;
          }
        } else {
          this.formDataToSend.summ = this.price;
        }
      },
      deep: true,
    },
    "formDataToSend.minute": {
      handler: function (val, oldVal) {
        if (this.triggerUpdate) {
          if (this.triggerSumm) {
            this.formDataToSend.summ = this.price;
          }
        } else {
          this.formDataToSend.summ = this.price;
        }
      },
      deep: true,
    },
    "formDataToSend.distance": {
      handler: function (val, oldVal) {
        if (this.triggerUpdate) {
          if (this.triggerSumm) {
            this.formDataToSend.summ = this.price;
          }
        } else {
          this.formDataToSend.summ = this.price;
        }
      },
      deep: true,
    },
    "formDataToSend.numberOfMovers": {
      handler: function (val, oldVal) {
        if (this.triggerUpdate) {
          if (this.triggerSumm) {
            this.formDataToSend.summ = this.price;
          }
        } else {
          this.formDataToSend.summ = this.price;
        }
      },
      deep: true,
    },
    "formDataToSend.time": {
      handler: function (val, oldVal) {
        if (this.triggerUpdate) {
          if (this.triggerSumm) {
            this.formDataToSend.summ = this.price;
          }
        } else {
          this.formDataToSend.summ = this.price;
        }
      },
      deep: true,
    },
    "formDataToSend.typesOfCars": {
      handler: function (val, oldVal) {
        this.setBodyTypes();
      },
      deep: true,
    },
  },
  methods: {
    getOrderDetail() {
      const { params } = this.$route;
      const url = `/${params.orderId}`;
      ordersApi
        .getOrderDetail(url)
        .then((res) => {
          this.$store.commit("order/setOrder", res.data);
          this.getLoadingTypes();
          this.getCargoFeatures();
          this.getStatuses();
        })
        .catch((error) => {
          const { data } = error.response;
          this.$store.commit("order/serError", data);
        })
        .finally(() => {
          this.$store.commit("order/setLoading", false);
        });
    },
    handleCloseClientModal() {
      this.clientsModal = false;
      this.getClientsList();
    },
    handleOpenClientModal() {
      this.clientsModal = true;
    },
    handleChooseClient(row) {
      this.formDataToSend.name = row.name;
      this.formDataToSend.id = row.id;
      this.handleCloseClientModal();
    },
    handleFilter(column) {
      this.orderSort = column;
      if (this.directionSort === null) {
        this.directionSort = true;
      } else {
        this.directionSort = !this.directionSort;
      }
      let url = `?order=${this.orderSort}&direction=${this.directionSort ? "asc" : "desc"}`;
      if (this.search != null) {
        url = url + `&search=${this.search}`;
      }
      this.$store.dispatch("clients/getClientsList", url);
    },
    getClientsList() {
      this.orderSort = "created_at";
      this.directionSort = false;
      const url = `?order=${this.orderSort}&direction=${this.directionSort ? "asc" : "desc"}`;
      this.$store.dispatch("clients/getClientsList", url);
    },
    sendNewOrder() {
      const data = orderSendData(this.formDataToSend);
      if (this.triggerUpdate) {
        if (this.order.status === "expired") {
          if (moment(data.date_time).unix() < moment().unix()) {
            this.timeModal = true;
            this.handleCloseConfirmModal();
            return false;
          }
        }
        const url = `/${this.order.id}`;
        this.$store.dispatch("order/updateOrder", { url: url, dataOrder: data });
        this.handleCloseConfirmModal();
      } else {
        this.$store.dispatch("order/createOrder", data);
      }
    },
    setBodyTypes() {
      if (this.formDataToSend.typesOfCars != null) {
        this.bodyTypes = this.typesOfCars[this.formDataToSend.typesOfCars.id].bodyTypes;
      }
    },
    getTypesOfCars() {
      this.$store.dispatch("common/getTypesOfCars");
      commonApi
        .getTypesOfCars()
        .then((res) => {
          this.$store.commit("common/setTypesOfCars", res.data);
          this.setBodyTypes();
        })
        .catch((error) => {
          const { data } = error.response;
          commit("common/setError", data);
        });
    },
    getLoadingTypes() {
      commonApi
        .getLoadingTypes()
        .then((res) => {
          this.$store.commit("common/setLoadingTypes", res.data);
          if (res.data.length > 0) {
            for (let i = 0; res.data.length > i; i++) {
              this.$set(this.formDataToSend.loadingTypes, res.data[i].id, false);
            }
            if (this.triggerUpdate) {
              if (this.order.loadings.length) {
                for (let i = 0; i < this.order.loadings.length; i++) {
                  const index = this.order.loadings[i].id;
                  this.formDataToSend.loadingTypes[index] = true;
                }
              }
            }
          }
        })
        .catch((error) => {
          const { data } = error.response;
          commit("common/setError", data);
        });
    },
    getCargoFeatures() {
      commonApi
        .getCargoFeatures()
        .then((res) => {
          this.$store.commit("common/setCargoFeatures", res.data);
          if (res.data.length > 0) {
            for (let i = 0; res.data.length > i; i++) {
              this.$set(this.formDataToSend.cargoFeatures, res.data[i].id, false);
            }
            if (this.triggerUpdate) {
              if (this.order.cargoFeatures.length) {
                for (let i = 0; i < this.order.cargoFeatures.length; i++) {
                  const index = this.order.cargoFeatures[i].id;
                  this.formDataToSend.cargoFeatures[index] = true;
                }
              }
            }
          }
        })
        .catch((error) => {
          const { data } = error.response;
          commit("common/setError", data);
        });
    },
    getStatuses() {
      commonApi
        .getStatuses()
        .then((res) => {
          this.$store.commit("common/setStatuses", res.data);
        })
        .catch((error) => {
          const { data } = error.response;
          commit("common/setError", data);
        });
    },
    getPaymentTypes() {
      commonApi
        .getPaymentTypes()
        .then((res) => {
          this.$store.commit("common/setPaymentTypes", res.data);
        })
        .catch((error) => {
          const { data } = error.response;
          commit("common/setError", data);
        });
    },
    handleOpenConfirmModal() {
      this.updateModal = true;
    },
    handleCloseConfirmModal() {
      this.updateModal = false;
    },
    handleCloseErrorModal() {
      this.$store.commit("order/setError", {});
    },
    handleCloseTimeModal() {
      this.timeModal = false;
    },
    getSettings() {
      this.$store.dispatch("settings/getSettings");
    },
    searchClients(data) {
      this.search = data;
      const url = `?search=${data}`;
      this.$store.dispatch("clients/getClientsList", url);
    },
  },
  computed: {
    ...mapState("order", ["error", "loadingProcess", "order"]),
    ...mapState("clients", ["clientsList"]),
    ...mapState("common", [
      "typesOfCars",
      "cargoFeatures",
      "loadingTypes",
      "statuses",
      "paymentTypes",
    ]),
    ...mapState("settings", ["settings"]),
    moversRate() {
      return this.settings?.data?.[0].value;
    },
    triggerUpdate() {
      if (this.$route.params.process === "update") {
        return true;
      }
      return false;
    },
    price() {
      let price = 0;
      if (this.formDataToSend.isCity) {
        const moversRate = this.formDataToSend.numberOfMovers * this.moversRate;
        const rate = moversRate + +this.formDataToSend.rate;
        const priceAll = this.formDataToSend.countOfHours * rate;
        price = price + priceAll;
        if (this.formDataToSend.minutes == "15") {
          const priceMinutes = rate * 0.25;
          price = price + priceMinutes;
        } else if (this.formDataToSend.minutes == "30") {
          const priceMinutes = rate * 0.5;
          price = price + priceMinutes;
        } else if (this.formDataToSend.minutes == "45") {
          const priceMinutes = rate * 0.75;
          price = price + priceMinutes;
        }
      } else {
        const moversRate = this.formDataToSend.numberOfMovers * this.moversRate;
        const rate = moversRate;
        const priceMovers = this.formDataToSend.countOfHours * rate;
        price = price + priceMovers;
        if (this.formDataToSend.minutes == "15") {
          const priceMinutes = rate * 0.25;
          price = price + priceMinutes;
        } else if (this.formDataToSend.minutes == "30") {
          const priceMinutes = rate * 0.5;
          price = price + priceMinutes;
        } else if (this.formDataToSend.minutes == "45") {
          const priceMinutes = rate * 0.75;
          price = price + priceMinutes;
        }
        if (this.formDataToSend.distance != null && this.formDataToSend.rate != null) {
          const priceCar = this.formDataToSend.distance * this.formDataToSend.rate;
          price = price + priceCar;
        }
      }
      return price;
    },
  },
  async mounted() {
    this.getClientsList();
    this.getTypesOfCars();
    this.getPaymentTypes();
    if (this.triggerUpdate) {
      const { params } = this.$route;
      const url = `/${params.orderId}`;
      ordersApi.getOrderDetail(url).then((res) => {
        this.formDataToSend = ordersListReverse(this.formDataToSend, res.data);
        setTimeout(() => {
          this.triggerSumm = true;
        }, 1000);
      });
      this.getOrderDetail();
    } else {
      this.getLoadingTypes();
      this.getCargoFeatures();
    }
    await this.getSettings();
  },
};
</script>

<style lang="scss" scoped>
.order-create {
  &__container {
    padding: 25px 17px;
  }

  &__label {
    font-size: $g-font-base-size;
    color: $mainSecondTextColor;
    padding: 27px 0 7px;

    &.big {
      font-size: 18px;
      font-weight: 700;
      color: $mainColor;
    }

    &.no-padding {
      padding-top: 0;
    }
    &.padding-bottom {
      padding-bottom: 10px;
    }
  }

  &__address-group {
    display: flex;
    align-items: center;
  }

  &__form-container {
    width: 360px;
  }

  &__button-date {
    @include flex-row;

    justify-content: space-between;

    &_item {
      width: 48%;
    }
  }

  &__chekbox-container {
    padding: 25px 0 15px;
  }

  &__checkbox {
    padding: 4px 0;
  }

  &__time {
    @include flex-row;
  }

  &__time_item {
    padding-right: 70px;
    width: fit-content;
  }

  &__movers {
    @include flex-row;

    align-items: center;
  }

  &__movers_disc {
    padding-right: 46px;
  }

  &__submit {
    width: 360px;
    padding-top: 15px;
  }

  &__nonstandart {
    display: flex;
  }

  &__nonstandart_item {
    width: 160px;
    margin-right: 10px;
  }

  &__price {
    padding-top: 25px;
  }

  &__type-loading {
    padding-bottom: 20px;
  }
}
.client-table__header {
  @include flex-row;

  justify-content: space-between;
  padding-bottom: 10px;
}
.client-table__search {
  width: 320px;
}
/deep/.custom-table__header {
  grid-template-columns: 1fr 1fr 1fr 0fr !important;
}
/deep/.custom-table__row {
  grid-template-columns: 1fr 1fr 1fr 0fr !important;
}

.statuses {
  /deep/.input-main-select {
    width: 100%;
    height: 48px;
  }
}
</style>
