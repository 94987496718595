<template>
  <div class="input-time">
    <label :for="label">
      <v-select
        :id="label"
        :placeholder="placeholder"
        :options="optionList"
        :value="value"
        label="name"
        :disabled="disabled"
        @input="onChange"
      >
      </v-select>
    </label>
    <div v-if="errorMessages.length">
      <p class="input-time__error">
        {{ errorMessages }}
      </p>
    </div>
    <div class="select-icon">
      <SelectIcon />
    </div>
  </div>
</template>

<script>
import SelectIcon from "../../../assets/images/icons/select-icon.svg";
import moment from "moment";

export default {
  name: "InputTime",
  components: {
    SelectIcon,
  },
  data() {
    return {
      optionList: [],
    };
  },
  props: {
    value: {
      type: [Object, String],
      default() {
        return {};
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    date: {
      type: String,
      default: "",
    },
  },
  methods: {
    onChange(value) {
      this.setValue[this.id] = value;
    },
    timeIteration() {
      this.optionList = [];
      for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 2; j++) {
          this.optionList.push({
            name: `${i < 10 ? "0" + i : i}` + ":" + (j === 0 ? "00" : 30 * j),
          });
        }
      }
    },
    timeIterationToday() {
      this.optionList = [];
      for (let i = +moment(new Date()).format("HH") + 1; i < 24; i++) {
        for (let j = 0; j < 2; j++) {
          this.optionList.push({
            name: `${i < 10 ? "0" + i : i}` + ":" + (j === 0 ? "00" : 30 * j),
          });
        }
      }
      if (+moment(new Date()).format("mm") < 30) {
        const hour = +moment(new Date()).format("HH");
        this.optionList.unshift({ name: `${hour < 10 ? "0" + hour : hour}` + ":30" });
      }
    },
  },
  mounted() {
    if (this.date === moment(new Date()).format("yyyy-MM-DD")) {
      this.timeIterationToday();
    } else {
      this.timeIteration();
    }
  },
  watch: {
    date: function (newVal, oldVal) {
      if (newVal === moment(new Date()).format("yyyy-MM-DD")) {
        // this.onChange("");
        this.timeIterationToday();
      } else {
        this.timeIteration();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-time {
  color: $mainColor;
  width: 100%;
  padding: 3px;
  border: 1px solid $mainColor;
  border-radius: 5px;
  height: 48px;
  position: relative;
  width: 100%;

  /deep/.vs__search,
  .vs__search:focus {
    font-size: 14px;
  }

  .select-icon {
    position: absolute;
    top: 35%;
    right: 5px;
  }

  &__error {
    color: $elementsColor;
    position: absolute;
    padding-top: 10px;
    font-size: $g-font-small-size;
  }
}

/deep/ .v-select {
  height: 100%;
}

/deep/ .vs__dropdown-toggle {
  border: none;
  height: 100%;
}
/deep/ .vs__dropdown-option--highlight {
  background-color: $mainColor;
}
/deep/ .vs__dropdown-menu {
  @include scrollBar;

  padding: 0;
  margin-top: 5px;
}
/deep/ .vs__clear {
  display: none;
}
/deep/ .vs__actions {
  display: none;
}
</style>
