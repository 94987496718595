<template>
  <div class="radio-input">
    <div class="radio-input__container">
      <div
        @click="setValue[id] = value.value"
        :class="[
          'radio-input__radio',
          setValue[id] === value.value && 'radio-input__radio--checked',
        ]"
        v-for="value of timeMinutes"
        :key="value.id"
      >
        <input
          type="radio"
          :id="value.id"
          v-model="setValue[id]"
          :value="value.value"
          :disabled="disabled"
        />
        <span>
          {{ value.value }}
        </span>
      </div>
      <div v-if="errorMessages.length">
        <p v-for="error in errorMessages" :key="error.id">
          {{ error }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputRadio",
  data() {
    return {
      timeMinutes: [
        {
          id: "time-1",
          value: "00",
        },
        {
          id: "time-2",
          value: "15",
        },
        {
          id: "time-3",
          value: "30",
        },
        {
          id: "time-4",
          value: "45",
        },
      ],
    };
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-input {
  @include flex-column;

  &__container {
    display: flex;
  }

  input {
    display: none;
  }

  &__container {
    @include flex-row;
  }

  &__radio {
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-right: 10px;
    width: 50px;
    height: 36px;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 5px;
    background: #d8d8d8;
    cursor: pointer;

    &--checked {
      border: 1px solid #131313;
    }

    span {
      display: flex;
      align-items: center;
    }
  }

  &--disabled {
    label {
      color: $mainDisabled;
      margin-left: 20px;
    }
  }

  label {
    margin-left: 20px;
    color: $mainSecondTextColor;
  }
}
</style>
