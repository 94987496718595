<template>
  <div class="input-date">
    <!-- :disabled-dates="disabledDates" -->
    <Datepicker
      :value="value"
      :language="ru"
      format="yyyy-MM-dd"
      placeholder="Выбрать"
      @selected="onChange"
      :monday-first="true"
    ></Datepicker>
    <div class="select-icon">
      <SelectIcon />
    </div>
    <div v-if="errorMessages.length">
      <p v-for="error in errorMessages" :key="error.id" class="input-date__error">
        {{
          error.indexOf("auth.") !== -1 || error.indexOf("user.") !== -1 ? error.slice(5) : error
        }}
      </p>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { ru } from "vuejs-datepicker/dist/locale";
import moment from "moment";

import SelectIcon from "../../../assets/images/icons/select-icon.svg";
export default {
  name: "InputDate",
  data() {
    return {
      ru: ru,
      disabledDates: {
        to: new Date(Date.now() - 864e5),
      },
    };
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    id: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    Datepicker,
    SelectIcon,
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("yyyy-MM-DD");
    },
    onChange(value) {
      this.setValue[this.id] = this.customFormatter(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-date {
  position: relative;
  width: 100%;

  .select-icon {
    position: absolute;
    top: 35%;
    right: 5px;
    z-index: 1;
  }

  &__error {
    position: absolute;
    color: $elementsColor;
    padding-left: 10px;
    padding-top: 10px;
    text-align: left;
    width: 500px;
    font-size: $g-font-small-size;
  }
}
/deep/.vdp-datepicker {
  position: relative;
  z-index: 2;

  input {
    width: 100%;
    border-radius: 5px;
    background: transparent;
    height: 48px;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 17px 0 16px 16px;
    border: 1px solid #131313;
    box-sizing: border-box;
    &::placeholder {
      color: #000000;
      padding: 16px 0px;
      font-size: 14px;
    }
  }

  .cell.day:hover {
    color: $mainSecondColor;
    border: none !important;
  }
  .cell.day.selected {
    color: #000000;
    background: $mainSecondColor;
  }
}
</style>
