<template>
  <div
    :class="[
      'text-default-input',
      isBordered ? 'text-default-input--bordered' : '',
      isAverage ? 'text-default-input--average' : '',
      disabled ? 'text-default-input--disabled' : '',
    ]"
  >
    <label :for="label">
      <input
        :id="label"
        v-model="setValue[id]"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled"
        readonly
      />
    </label>
    <div v-if="errorMessages.length">
      <p v-for="error in errorMessages" :key="error.id" class="text-default-input__error">
        {{ error }}
      </p>
    </div>
    <div class="select-icon">
      <SelectIcon />
    </div>
  </div>
</template>

<script>
import SelectIcon from "../../../assets/images/icons/select-icon.svg";
export default {
  name: "InputModal",
  components: {
    SelectIcon,
  },
  props: {
    isAverage: {
      type: Boolean,
      default: false,
    },
    isBordered: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: [Function, Boolean],
      default: false,
    },
  },
  computed: {
    error(error) {
      const semi = error.indexOf(".");
      if (semi) {
        error = error.slice(semi + 1);
        return error;
      } else {
        return error;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-default-input {
  position: relative;
  width: 100%;

  .select-icon {
    position: absolute;
    top: 35%;
    right: 5px;
  }
  input {
    width: 100%;
    background: $g-color-white;
    border-radius: 5px;
    height: 46px;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 17px 0 16px 16px;
    cursor: pointer;
    &::placeholder {
      color: $mainTextColor;
      padding: 16px 0px;
      font-size: 14px;
    }
  }
  &--average {
    input {
      height: 36px;
    }
  }
  &--bordered {
    input {
      border: 1px solid #131313;
      box-sizing: border-box;
    }
  }
  &__error {
    color: $elementsColor;
    position: absolute;
    padding-left: 10px;
    padding-top: 10px;
    text-align: left;
    font-size: $g-font-small-size;
  }

  &--disabled {
    /deep/input {
      background: #efefef;
    }
  }
}
/deep/input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
/deep/input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/deep/input[type="number"] {
  -moz-appearance: textfield;
}
</style>
