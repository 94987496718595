<template>
  <div class="choose-map">
    <ButtonMain class="choose-map__button" @click.native="handleOpenChooseModal" :button-grey="true"
      >Выбрать на карте</ButtonMain
    >
    <DefaultModal :modalCenter="true" v-if="chooseModal" :on-close="handleCloseChooseModal">
      <div ref="map" style="width: 100%; height: 350px"></div>
      <div class="choose-map__container">
        <InputText
          :set-value="formDataToSend"
          :id="'address'"
          :label="'address'"
          :type="'text'"
          :placeholder="''"
          isBordered
        />
        <ButtonMain @click.native="handleConfirmAddress">Ок</ButtonMain>
      </div>
      <div v-if="error" class="choose-map__error">
        *Введите адрес вручную или выберите другую точку на карте.
      </div>
    </DefaultModal>
  </div>
</template>

<script>
import View from "ol/View";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import { Style, Icon } from "ol/style";
import { fromLonLat, transform } from "ol/proj";
import axios from "axios";
import keysToCamelCase from "camelcase-keys";

import "ol/ol.css";

import DefaultModal from "../molecules/DefaultModal.vue";
import ButtonMain from "../atoms/buttons/ButtonMain.vue";
import InputText from "../atoms/inputs/InputText.vue";

export default {
  name: "MapChoose",
  data() {
    return {
      chooseModal: false,
      map: null,
      mapLat: 48.46521,
      mapLng: 35.0397828,
      mapDefaultZoom: 12,
      coordinate: {
        lon: null,
        lat: null,
      },
      address: {},
      formDataToSend: {
        address: null,
      },
      error: false,
    };
  },
  props: {
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    name: {
      type: String,
      default: null,
    },
  },
  components: {
    DefaultModal,
    ButtonMain,
    InputText,
  },
  methods: {
    handleConfirmAddress() {
      if (this.formDataToSend.address != null && this.formDataToSend.address != "") {
        const addressSend = this.address;
        addressSend.address.road = this.formDataToSend.address;
        addressSend.address.house_number = null;
        addressSend.address.city = null;
        addressSend.address.town = null;
        addressSend.address.village = null;
        addressSend.lat = this.coordinate.lat;
        addressSend.lon = this.coordinate.lon;
        addressSend.address.houseNumber = null;
        this.setValue[this.name] = addressSend;
        this.chooseModal = false;
      } else {
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 4000);
      }
    },
    handleCloseChooseModal() {
      this.chooseModal = false;
    },
    handleOpenChooseModal() {
      this.chooseModal = true;
      setTimeout(() => {
        this.initializeMap();
        this.eventMapClick();
        this.setMarker();
      }, 0);
    },
    setMarker() {
      if (this.setValue[this.name] != null) {
        this.coordinate.lon = this.setValue[this.name].lon;
        this.coordinate.lat = this.setValue[this.name].lat;
        this.addMarker(this.coordinate);
        this.address = this.setValue[this.name];
        this.formDataToSend.address = `${
          this.address.address.village != undefined && this.address.address.village != null
            ? this.address.address.village
            : ""
        }${
          this.address.address.city != undefined && this.address.address.city != null
            ? this.address.address.city
            : ""
        }${
          this.address.address.town != undefined && this.address.address.town != null
            ? this.address.address.town
            : ""
        }${
          (this.address.address.city != undefined && this.address.address.city != null) ||
          (this.address.address.village != undefined && this.address.address.village != null) ||
          (this.address.address.town != undefined && this.address.address.town != null)
            ? ", "
            : ""
        }${
          this.address.address.road != undefined && this.address.address.road != null
            ? this.address.address.road
            : ""
        }${
          this.address.address.houseNumber != undefined && this.address.address.houseNumber != null
            ? ", "
            : ""
        }${
          this.address.address.houseNumber != undefined && this.address.address.houseNumber != null
            ? this.address.address.houseNumber
            : ""
        }`;
      }
    },
    initializeMap() {
      this.map = new Map({
        target: this.$refs["map"],
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
        ],
        view: new View({
          zoom: this.mapDefaultZoom,
          center: fromLonLat([this.mapLng, this.mapLat]),
          constrainResolution: true,
        }),
      });
    },
    async reverseGeocode(coords) {
      const url =
        "https://nominatim.openstreetmap.org/reverse?format=json&lon=" +
        coords[0] +
        "&lat=" +
        coords[1];
      let response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      response.data = keysToCamelCase(response.data, { deep: true });
      this.coordinate.lon = coords[0];
      this.coordinate.lat = coords[1];
      this.address = response.data;
      this.formDataToSend.address = `${
        this.address.address.village != undefined && this.address.address.village != null
          ? this.address.address.village
          : ""
      }${
        this.address.address.city != undefined && this.address.address.city != null
          ? this.address.address.city
          : ""
      }${
        this.address.address.town != undefined && this.address.address.town != null
          ? this.address.address.town
          : ""
      }${
        (this.address.address.city != undefined && this.address.address.city != null) ||
        (this.address.address.village != undefined && this.address.address.village != null) ||
        (this.address.address.town != undefined && this.address.address.town != null)
          ? ", "
          : ""
      }${
        this.address.address.road != undefined && this.address.address.road != null
          ? this.address.address.road
          : ""
      }${
        this.address.address.houseNumber != undefined && this.address.address.houseNumber != null
          ? ", "
          : ""
      }${
        this.address.address.houseNumber != undefined && this.address.address.houseNumber != null
          ? this.address.address.houseNumber
          : ""
      }`;
      this.addMarker(this.coordinate);
    },
    deleteLayer(layerName) {
      const layersToRemove = [];
      this.map.getLayers().forEach((layer) => {
        if (layer?.get("name") != undefined && layer.get("name") === layerName) {
          layersToRemove.push(layer);
        }
      });
      const len = layersToRemove.length;
      for (var i = 0; i < len; i++) {
        this.map.removeLayer(layersToRemove[i]);
      }
    },
    addMarker(data) {
      this.deleteLayer("address");
      const iconFeature = new Feature({
        geometry: new Point(
          transform([parseFloat(data.lon), parseFloat(data.lat)], "EPSG:4326", "EPSG:3857"),
        ),
        name: "address",
      });

      const iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 0.6],
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
          src: "/images/img-map/new-order.png",
        }),
      });

      iconFeature.setStyle(iconStyle);

      const vectorSource = new VectorSource({
        features: [iconFeature],
      });

      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });

      vectorLayer.set("name", "address");

      this.map.addLayer(vectorLayer);
      this.map.getView().setCenter(fromLonLat([data.lon, data.lat]));
      this.map.getView().setZoom(12);
    },
    eventMapClick() {
      this.map.on("click", (event) => {
        var coord = ol.proj.toLonLat(event.coordinate);
        this.reverseGeocode(coord);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.choose-map {
  &__button {
    margin-left: 20px;
    width: 200px;
  }

  &__container {
    padding-top: 10px;
    display: flex;

    /deep/.text-default-input {
      width: 350px;
    }

    /deep/.button-main {
      margin-left: 20px;
      width: 120px;
    }
  }

  &__error {
    position: absolute;
    bottom: 10px;
    padding-top: 15px;
    font-size: 14px;
    color: $elementsColor;
  }

  /deep/.modal-container {
    left: 487px !important;
    transform: translate(0, -50%) !important;
  }

  /deep/.modal {
    background: none;
  }
}
</style>
